const BASE_URL = "https://asia-south1-be-gymmie.cloudfunctions.net/api";
const ATTENDANCE_URL = "https://asia-south1-be-gymmie.cloudfunctions.net/attendanceSystem";
const BEARER_TOKEN = "YOUR_BEARER_TOKEN";

let HOST = "https://asia-south1-be-gymmie.cloudfunctions.net/app/";

export const GetRandomString = () => {
  return (Math.random() + 1).toString(36).substring(7);
}

const putApiCall = async (url, token, body={}) => {
  const requestOptions = {
      method: 'PUT',
      headers: { 
          'Content-Type': 'application/json',
          Authorization : `Bearer ${token}`
      },
      body: JSON.stringify(body)
  };
  try {
      let res = await fetch(HOST + url, requestOptions)
      return res;
  } catch(e) {
      return {};
  }
}

const postApiCall = async (url, token, body={}) => {
  const requestOptions = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
          Authorization : `Bearer ${token}`
      },
      body: JSON.stringify(body)
  };
  try {
      let res = await fetch(HOST + url, requestOptions)
      return res;
  } catch(e) {
      return {};
  }
}

export const getToken = async (queryParamValue) => {
  try {
    const response = await fetch(
      `${BASE_URL}/auth/getToken/${queryParamValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
};


export const createBusiness = async (gymName, phone) => {
  try {
    const response = await fetch(`${BASE_URL}/createBusiness`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BEARER_TOKEN}`,
      },
      body: JSON.stringify({ gymName, phone }),
    });

    if (!response.ok) {
      throw new Error("Signup failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Signup failed");
  }
};

export const createEvent =  async (phone, id, data, events) => {
  let url = "updateBusiness";
    events = events || {};
    let memberData = {phone, events}
    id = id || GetRandomString();
    memberData.events[id] = {
        "date": data.date || new Date().toISOString(),
        "name": data.name,
        "photo": data.photo,
        "description": data.description,
        "prize": data.prize
    }
    let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, memberData);
    return res; 
};

export const createMember = async (phone, id, data) => {
  let url = "updateBusiness";
  let members = {};
  let memberData = {phone, members}
  id = id || GetRandomString();
  memberData.members[id] = {
      "email": data.email,
      "name": data.name,
      "gender": data.gender,
      "phoneNumber": data.phoneNumber,
      "bmi": data.bmi,
      "dob": data.dob,
      "membership":{
          "plan": data.plan,
          "isActive": "true",
          "startDate": data.startDate || new Date().toISOString(),
      }
  }
  if(data.photo){
      memberData.members[id]["photo"] = data.photo;
  }
  let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, memberData);
  return res; 
}

export const addStaff = async (phone, id, data) => {
  let url = "updateBusiness";
  let staff = {};
  let memberData = {phone, staff}
  id = id || GetRandomString();
  memberData.staff[id] = {
      "role": data.role,
      "email": data.email,
      "phone": data.phone,
      "salaryPerMonth": data.salaryPerMonth,
      "name": data.name,
      "photo": data.photo
  }
  let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, memberData);
  return res; 
}

export const createEquipment =  async (phone, id, data, EM) => {
    let url = "updateBusiness";
    EM = EM || {};
    let equipmentData = {phone,"operations":{ EM }}
    let obj = {
        "type": data.type,
        "maintenanceCycle": parseInt(data.maintenanceCycle),
        "name": data.name,
        "description": data.description,
        "lastMaintenance": data.lastMaintenance || new Date(),
        "daysTillNextMaintenance": parseInt(data.daysTillNextMaintenance || data.maintenanceCycle || 30)
    }
    id = id || GetRandomString();
    equipmentData.operations.EM[id] = obj;
    let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, equipmentData);
    return res; 
};

export const updateDoneMaintennance = async (phone, item, ems) => {
  ems[item.key] = {...item, lastMaintenance: new Date().toISOString(), daysTillNextMaintenance: parseInt(item.maintenanceCycle) };
  let operations = { "EM": ems }
  let equipmentData = {phone, operations};
  let url = "updateBusiness";
  let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, equipmentData);
  return res; 
}

export const deleteMember = async (phone, id) => {
  let url = "updateBusiness";
  let members = {
    [id]: {}
  };
  let memberData = {phone, members};
  let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, memberData);
  return res; 
}

export const deleteStaff = async (phone, id) => {
  let url = "updateBusiness";
  let staff = {
    [id]: {}
  };
  let memberData = {phone, staff};
  let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, memberData);
  return res; 
}

export const getPermission = async (phone, role, businessPhone) => {
  let url = "/getPermission?phone=" + phone;
  businessPhone = businessPhone || phone;
  let obj = {
    role, businessPhone, phone
  }
  url = BASE_URL + url;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BEARER_TOKEN}`,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      throw new Error("Failed to get permission");
    }

    return response.json();
  } catch (error) {
    throw new Error("Signup failed");
  }
}


export const deleteEvent = async (phone, id) => {
  let url = "updateBusiness";
  let events = {
    [id]: {}
  };
  let memberData = {phone, events};
  let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, memberData);
  return res; 
}

export const deleteEquipment = async (phone, id) => {
  let url = "updateBusiness";
  let ems = {};
  ems[id] = {};
  let operations = { "EM": ems }
  let equipmentData = {phone, operations};
  let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, equipmentData);
  return res; 
}

export const getBusinessDetails = async (queryParamValue) => {
  try {
    const response = await fetch(
      `${BASE_URL}/getBusinessDetails?phone=${queryParamValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
};

export const getUserDetails = async (queryParamValue) => {
  try {
    const response = await fetch(
      `${BASE_URL}/getUser?phone=${queryParamValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
};

export const getBusinessOfferings = async (queryParamValue) => {
  try {
    const response = await fetch(
      `${BASE_URL}/getBusinessOfferings?phone=${queryParamValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
};

export const registerEvent = async (eventKey, phoneNumber, businessPhone) => {
  try {
    let url = HOST + "eventRegistration?phone=" + phoneNumber;
    let event = {
      "businessPhone": businessPhone,
      "registeredEvents": {
          "eventKey": eventKey,
          "userDetails": {
              "phone": phoneNumber
          }
      }
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BEARER_TOKEN}`,
      },
      body: JSON.stringify(event)
    })
    return response.json()
  } catch (error) {
    throw new Error("Request failed");
  }
}

export const unRegisterEvent = async (eventKey, phoneNumber, businessPhone) => {
  try {
    let url = HOST + "unregisterEvent?phone=" + phoneNumber;
    let event = {
      "businessPhone": businessPhone,
      "eventKey": eventKey,
      "userPhone": businessPhone,
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BEARER_TOKEN}`,
      },
      body: JSON.stringify(event)
    })
    return response.json()
  } catch (error) {
    throw new Error("Request failed");
  }
}

export const buyService = async (serviceKey, phoneNumber, businessPhone, category) => {
  try {
    let url = "updateBusiness?phone=" + phoneNumber;
    let event = {
      "phone": phoneNumber,
      "purchasedService":  {
            "category": category,
            "serviceKey": serviceKey,
            "userDetails":{
              "phone": businessPhone
            } 
          }
      }
    
    let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, event);
    return res.json(); 
  } catch (error) {
    throw new Error("Request failed");
  }
}

export const getSingleBusinessDetails = async (queryParamValue) => {
  try {
    let data = await getBusinessDetails(queryParamValue);
    return data[0];
  } catch (error) {
    throw new Error("Request failed");
  }
};

//update business
// Simulate API update logic
const simulateApiUpdate = async (updatedDetails) => {

  let data = {...updatedDetails};
  let url = "updateBusiness";

  let res = await putApiCall(url, `Bearer ${BEARER_TOKEN}`, data);
  return res; 
};

// Update business details
export const updateBusinessDetails = async (updatedDetails) => {
  try {
    const response = await simulateApiUpdate(updatedDetails);
    return response;
  } catch (error) {
    console.error("Error updating business details:", error);
    throw error;
  }
};

export const markAttendance = async (phone, image) => {
  try {
    const response = await fetch(`${ATTENDANCE_URL}/compare`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ phone, image })
    })
    return response.json()
  } catch (error) {
    throw new Error("Request failed");
  }
}

export const postSchedule = async (phone) => {
  try {
    const body = `{
      "days": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
      ],
      "gymOpenTime": "06:00",
      "gymCloseTime": "22:00",
      "shiftDuration": "2",
      "trainerNeededDuringPeakHour": "2"
  }`
    console.log("body for schedule :", body)
    const response = await fetch(`${BASE_URL}/schedule?phone=${phone}`,
      {
        method: "POST",
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`
        },
        body: body
      })
    return response.json()
  } catch (error) {
    throw new Error("Request failed", error);
  }
}


export const getSchedule = async (queryParamValue) => {
  try {
    const response = await fetch(
      `${BASE_URL}/getSchedule?phone=${queryParamValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
};

export const getAttendance = async (queryParamValue) => {
  try {
    const response = await fetch(
      `${BASE_URL}/getAttendance?phone=${queryParamValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
};


export const getRevenueData = async (queryParamValue, filter) => {
  try {
    const response = await fetch(
      `${BASE_URL}/getRevenueDetails?phone=${queryParamValue}&filter=${filter}`,
      // `${BASE_URL}/getRevenueDetails?phone=7022623975&filter=yearly`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
};

export const editEnquiry = async (id, obj) => {
  try {
    obj = {
      ...obj, status: "Open"
    }
    obj.metadata = obj.metadata || {};
    obj.metadata["lastUpdatedOn"] = {
      "_seconds": parseInt(new Date().getTime()/1000),
      "_nanoseconds": 0
    }
    const response = await fetch(`${BASE_URL}/${window.localStorage.franchiseName}/${window.localStorage.branchName}/enquiry/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BEARER_TOKEN}`,
      },
      body: JSON.stringify(obj),
    });

    // if (!response.ok) {
    //   throw new Error(response.error);
    // }

    return response.json();
  } catch (error) {
    throw new Error(error);
  }
}

export const getEnquiryByNumber = async (phone) => {
  try {
    const response = await fetch(`${BASE_URL}/${window.localStorage.franchiseName}/${window.localStorage.branchName}/enquiry/${phone}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BEARER_TOKEN}`,
      }
    });

    // if (!response.ok) {
    //   throw new Error(response.error || response.body);
    // }

    return response.json();
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
}


export const addEnquiry = async (obj) => {
  try {
    obj = {
      ...obj, 
      status: "Open"
    }
    obj.metadata = obj.metadata || {};
    obj.metadata["createdOn"] = {
      "_seconds": parseInt(new Date().getTime()/1000),
      "_nanoseconds": 0
    }
    const response = await fetch(`${BASE_URL}/${window.localStorage.franchiseName}/${window.localStorage.branchName}/enquiry`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BEARER_TOKEN}`,
      },
      body: JSON.stringify(obj),
    });

    // if (!response.ok) {
    //   throw new Error(response.error);
    // }

    return response.json();
  } catch (error) {
    throw new Error(error);
  }
}

export const getEnquiries = async () => {

  try {
    const response = await fetch(`${BASE_URL}/${window.localStorage.franchiseName}/${window.localStorage.branchName}/enquiries`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    // if (!response.ok) {
    //   throw new Error("Request failed");
    // }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
}

export const authLogin = async (phone) => {
  
  try {
    const response = await fetch(`${BASE_URL}/auth/login/${phone}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    // if (!response.ok) {
    //   throw new Error("Request failed");
    // }

    return response.json();
  } catch (error) {
    throw new Error(error.toString);
  }
}

export const getAllAssignments = async (obj) => {

  try {
    const response = await fetch(`${BASE_URL}/assignment/${window.localStorage.franchiseName}/${window.localStorage.branchName}/8979171998`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
}

export const getAllStaffs = async (obj) => {

  try {
    const response = await fetch(`${BASE_URL}/staff/${window.localStorage.franchiseName}/${window.localStorage.branchName}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Request failed");
    }

    return response.json();
  } catch (error) {
    throw new Error("Request failed");
  }
}