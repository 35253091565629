import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import { faUserPlus, faTicketAlt, faEdit, faUser, faCheck, faIdCard, faL } from "@fortawesome/free-solid-svg-icons";
import { getBusinessDetails, getEnquiryByNumber, getAllStaffs, getEnquiries, authLogin } from "./../../services/api";
import { getPermissions } from "./../../global/util";
import { useDataContext } from './../../DataContext'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { BarChart, Bar, ResponsiveContainer } from 'recharts';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import dpImage from '../../assets/images/dp.png';
import trendingUp from "./../../assets/images/trendingUp.png";
import { Calendar } from 'primereact/calendar';
import AddEnquiry from "./addEnquiry";
import EnquiryHeader from "./enquiryHeader";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import loading from "./../../assets/images/loading.gif"

function secondsToDate(seconds) {
  if(!seconds) return "";
  var milliseconds = seconds * 1000;

  // Create a new Date object
  var date = new Date(milliseconds);

  // Extract date components
  var year = date.getFullYear();
  var month = date.getMonth() + 1; // Month is zero-based, so we add 1
  var day = date.getDate();

  // Construct the date string
  var dateString = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');

  return dateString;
}

let textHeader = {
  color: "#727272",
  fontSize: 13
}

let textObj = {
  color: "#000",
  fontSize: 14,
}

let assignmentText = {
  backgroundColor: "#ddd",   
  marginTop: 5, 
  padding: 4,
  fontSize: 13,
  borderRadius: 4,
  paddingLeft: 10
}

let assignmentHeaderText = {
  fontSize: 14,
  fontWeight: "bold",
  margin: "10px 0px 10px 0px"
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


function Enquiry() {

  const navigate = useNavigate();
  const [ openEnquiry, setEnquiry ] = useState(false);
  const [ showErrolModal, setShowErrorModal ] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { apiData, setApiData } = useDataContext();
  const [ edit, setEdit ] = useState(false);
  const [ selectedEnquiry, setSelectedEnquiry ] = useState(false);
  const [ searchNumber, setSearchNumber ] = useState(false);
  const [ showLogout, setShowLogout ] = useState(false);

  const [ shouldRefresh, setShouldRefresh ] = useState(2);

  useEffect(() => {
    if(!window.localStorage.franchiseName ) {
      window.location.href = "/";
      return;
    }
    fetchData();
  }, []);

  const phoneNumber = window.localStorage.phoneNumber;

  const _setRefresh = async () => {
    let randomNumber = getRandomInt(100);
    setShouldRefresh(randomNumber);
  }

  const hideErrorModal = () => {
    setShowErrorModal("");
  }

  const toggleEdit = (flag) => {
    setEdit(flag);
  }

  const fetchData = async () => {
    try {
      let businessDetails = await getBusinessDetails(phoneNumber);
      setApiData({ ...businessDetails[0] });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const toggleErrorMessage = (message) => {
    setShowErrorModal(message)
  }

  const toggleEnquiry = (flag) => {
    setEnquiry(flag);
    _setRefresh();
  }

  const showLogoutModal = (flag) => {
    setShowLogout(flag)
  }

  if(isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-message">
          <img src={loading} alt="Loading..." />
        </div>
      </div>
    ) 
  }
 
  return (
    <div className="container-fluid" style={{"backgroundColor":"#eee"}}>
      <EnquiryHeader toggleErrorMessage={toggleErrorMessage} toggleEnquiry={toggleEnquiry} showLogoutModal={(flag) => showLogoutModal(flag)}
        searchNumber={(val) => setSearchNumber(val)} toggleEdit={toggleEdit} />
      <div class="row" style={{height: "calc(100vh + -70px)", overflow: "scroll"}}>
        <div class="col-sm-2" style={{ height: "100%", width: "14%" }}>
        </div>
        <div class="col-sm-7" style={{height: "100%", marginTop: 24, width: "60%"}}>
          <EnquiryStatus shouldRefresh={shouldRefresh} toggleEnquiry={toggleEnquiry} toggleErrorMessage={toggleErrorMessage}
            toggleEdit={toggleEdit} setSelectedEnquiry={setSelectedEnquiry} searchNumber={searchNumber} />
          {/* <EnquirySummary /> */}
          {/* <Assignments /> */}
        </div>
        {/* <div class="col-sm-3" style={{height: "100%", marginTop: 8, backgroundColor: "rgba(0,0,0,0.7)", zIndex: 999 }}>
          <Reminder />
          <p style={{ border: "0.5px solid #727272", marginTop: "12px", marginBottom: "12px", marginLeft: "5%", width: "90%"}} />
          <OnDuty />
          <p style={{ border: "0.5px solid #727272", marginTop: "12px", marginBottom: "12px", marginLeft: "5%", width: "90%"}} />
          <Calendar inline showWeek style={{ width: "90%", marginLeft: "5%" }} />
          <Events />
        </div> */}
      </div>
      <AddEnquiry openEnquiry={openEnquiry} toggleEnquiry={toggleEnquiry} edit={edit} selectedEnquiry={selectedEnquiry}
        toggleErrorMessage={toggleErrorMessage} setRefresh={_setRefresh} />
      <ErrorModal showErrorModal={showErrolModal} hideErrorModal={hideErrorModal} />
      {
        showLogout ? <LogoutModal showLogoutModal={(flag) => showLogoutModal(flag)} /> : null 
      }
    </div>
  )

}

function EnquirySummary() {
  return (
    <div className="container-fluid" style={{"backgroundColor":"#eee", height: "10%", marginTop: 24}}>
      <div class="row" style={{height: "100%", display: "flex", justifyContent: "space-between", margin: 0 }}>
       {
        [...Array(9)].map((item, index) => {
            return <div style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              height: 80,
              width: 80,
              background: "#FFFFFF",
              boxShadow: "4px 4px 10px 2px rgba(114, 114, 114, 0.8)",
              borderRadius: 15,
              padding: 5,
            }}>
              <div style={{ fontSize: 10 }}> Total Enquiry </div>
              <div style={{ fontSize: 14, fontWeight: 700 }}> 200 </div>
              <div>
                <img src={trendingUp} style={{ width: 16, height: 16 }} />
              </div>
            </div>
        })
       }
      </div>
    </div>
  )
}

let enquiryStatusObj = {
  height: "95%", 
  boxShadow: "4px 4px 10px 2px rgba(114, 114, 114, 0.8)", 
  borderRadius: "15px",
  overflow: "scroll"
}


function EnquiryStatus(props) {

  const [enquiries, setEnquiries] = useState([]);

  const _getEnquiries = async () => {
    try {
       let res = await getEnquiries();
       setEnquiries(res);
    } catch(err) {
        props.toggleErrorMessage(err?.error || err?.message);
    }
  }

  // useEffect(() => {
  //   _getEnquiries();
  // }, [props.shouldRefresh, props.shouldRefresh])

  const searchByNumber = async (val) => {
    try {
      let res = await getEnquiryByNumber(val);
      if(res?.error) {
        return props.toggleErrorMessage(res?.error || res?.message)
      }
      res = [res];
      setEnquiries(res);
    } catch(err) {
      console.log(err);
      props.toggleErrorMessage(err?.error || err?.message)
    }
  }

  useEffect(() => {
    if(props.searchNumber) {
      searchByNumber(props.searchNumber)
    } else {
      _getEnquiries();
    }
  }, [props.searchNumber, props.shouldRefresh]);

  const getColor = (status) => {
    if(!status) return "green";
    status = status.toLowerCase();
    if(status == "open") return "#ffc107";  
    if(status == "in-progress") return "blue";
    if(status == "expired") return "red";
    return "green"
  }


  return (
    <div className="container-fluid" style={enquiryStatusObj}>
      <div class="row">
        <div class="col" style={{ 
          fontSize: 16,
          fontWeight: 700,
          marginTop: 16,
          marginBottom: 16,
         }}>Enquiry Status</div>
      </div>
      <div class="row" style={{ paddingBottom: 8, paddingTop: 8 }}>
        <div class="col-sm-2" style={textHeader}> Name </div>
        <div class="col-sm-2" style={textHeader}> Source </div>
        <div class="col-sm-2" style={textHeader}> Reference </div>
        <div class="col-sm-2" style={textHeader}> Enquiry Date </div>
        <div class="col-sm-2" style={textHeader}> POC </div>
        <div class="col-sm-1" style={textHeader}> Status </div>
        <div class="col-sm-1" style={textHeader}>  </div>
      </div>
      {
        enquiries.reverse().map((item, index) => <div class="row" key={index} style={{ paddingBottom: 8, paddingTop: 8 }}>
          <div class="col-sm-2" style={textObj}> { item.name } </div>
          <div class="col-sm-2" style={textObj}> { item.source } </div>
          <div class="col-sm-2" style={textObj}> { item.reference } </div>
          <div class="col-sm-2" style={textObj}> { secondsToDate(item?.metadata?.createdOn?._seconds || item?.metadata?.lastUpdatedOn?._seconds || 0) } </div>
          <div class="col-sm-2" style={textObj}> { item.poc } </div>
          <div class="col-sm-1" style={{ ...textObj, color: getColor(item.status), fontWeight: "bold" }}> { item.status } </div>
          <div class="col-sm-1" style={{ ...textObj, display: "flex", justifyContent: "center" }} onClick={()=>{
            props.toggleEnquiry(true);
            props.toggleEdit(true);
            props.setSelectedEnquiry(item);
          }}> 
            <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} />  
          </div>
        </div>)
      }
    </div>
  )
}

function Reminder() {
  return (
    <div className="container-fluid" style={{
      height: "30%",
      overflow: "scroll"
    }}>
      <div class="row">
        <div class="col" style={{ 
          fontSize: 16,
          fontWeight: 700,
          marginTop: 16,
          marginBottom: 16,
         }}> Reminder </div>
      </div>
      {
        [...Array(5)].map((item, index) => <div class="row" style={{ paddingBottom: 8,
          paddingTop: 8,
          backgroundColor: "rgb(0,0,0,0.2)",
          borderRadius: 8,
          color: "white",
          fontSize: 14,
          marginBottom: 8,
          overflow: "scroll"
        }}>
          <span>
            Lat pull down machine for service 
          </span>
        </div>)
      }
    </div>
  )
}


let tabObj = {
  width: 150,
  fontSize: 14
}
function Assignments() {
  const [key, setKey] = useState('home');
  const [staffs, setStaffs] = useState([]);

  const _getStaffs = async () => {
    let res = await getAllStaffs();
    res = res.map(item => Object.assign({}, {name: item.staffName, phone: item.staffPhone, id: item.staffId}));
    setStaffs(res);
    return res;
  }

  useEffect(() => {
    // _getStaffs();
  }, [])

  return (
    <div className="container-fluid" style={enquiryStatusObj}>
      <div className="row">
        <div style={{ display: "flex" }}>
          {
          staffs.map(item => (
            <div style={tabObj}>
              {item.name}
            </div>
          ))
        }

        </div>
        
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div style={assignmentHeaderText}>Assigned</div>
          <div style={assignmentText}>
            Follow up with Mohit Kumar
          </div>
        </div>
        <div className="col-sm-3">
          <div style={assignmentHeaderText}>In-Progress</div>
          <div style={assignmentText}>
            Follow up with Mohit Kumar
          </div>
        </div>
        <div className="col-sm-3">
          <div style={assignmentHeaderText}>Complete</div>
          <div style={assignmentText}>
            Follow up with Mohit Kumar
          </div>
        </div>
        <div className="col-sm-3">
          <div style={assignmentHeaderText}>Missed</div>
          <div style={assignmentText}>
            Follow up with Mohit Kumar
          </div>
        </div>

        <div className="col-sm-3"></div>
        <div className="col-sm-3"></div>
      </div>
    </div>
  );
}

function OnDuty() {
  return (
    <div className="container-fluid">     
      <div className="row">
        <div className="col-sm-6" style={{ 
          fontSize: 16,
          fontWeight: 700,
          marginBottom: 4,
         }}> On-Duty </div>
      </div>
      <div className="row">
        {
          [...Array(2)].map((item) => <div className="col-sm-6">
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "30%" }}>
                  <img src={dpImage} style={{ width: 50, height: 50 }} />
                </div>
                <div style={{ width: "70%", marginLeft: 8, marginLeft: 8, display: "flex", justifyContent: "center", flexDirection: "column" }}>   
                  <p style={{ marginBottom: 0, fontSize: 14 }}>Raghib</p>
                  <p style={{ marginBottom: 0, fontSize: 12 }}>Gym Manager</p>
                </div>
              </div>
            </div>)
        }
      </div>
    </div>
  )
}


function Events() {
  return (
    <div className="container-fluid">
      {
        [...Array(2)].map((item) => <div className="row" style={{ 
            display: "flex", width: "100%", height: 80, margin: 10, boxShadow: "4px 4px 10px 2px rgba(114, 114, 114, 0.2)", borderRadius: 5,
          }}>
            <div style={{ width: "1%", backgroundColor: "gray" }}></div>
            <div style={{ width: "30%" }}>
              <span style={{ fontSize: 14 }}> Birthday </span>
            </div>
            <div style={{ width: "55%", padding: "5px 0px 5px 0px" }}>   
                <p style={{ marginBottom: 0, fontSize: 12 }}>Mohammad Raghib</p>
                <p style={{ marginBottom: 0, fontSize: 12 }}>Sushil Nishant Devan</p>
            </div>
          </div>)
      }
    </div>
  )
}

function ErrorModal(props) {

  useEffect(() => {
    if(props.showErrorModal) {
      setTimeout(()=> {
        props.hideErrorModal();
      }, 5000);
    }
  }, [props.showErrorModal])

  console.log(props.showErrorModal);

  if(!props.showErrorModal) {
    return
  }

  let variant = "success"
  return (
    <Alert key={variant} variant={variant} style={{ 
      position: "fixed",top: "7%",left: "26%",padding: "0.5rem",width: "45%"
  }}>
      { props.showErrorModal }
    </Alert>
  )
}

let obj = {
  height: "100vh",  
  width: "100vw", 
  position: "fixed",
  top: "0px",
  left: "0px",
  backgroundColor: "rgb(0,0,0,0.8)",
  zIndex: 9
}

function LogoutModal(props) {
  return (
    <div style={obj}>
      <div
        className="modal show"
        style={{ display: 'block', position: 'initial' }}
      >
        <Modal.Dialog centered>
          <Modal.Header>
            <Modal.Title>Are you sure, you want to logout</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
                          props.showLogoutModal(false);
            }}>Cancel</Button>
            <Button variant="danger" onClick={() => {
                          window.localStorage.clear();
                          window.location.href = "/";
                          props.showLogoutModal(false);
                      }}>Logout</Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </div>
  );
}


export default Enquiry;
