import React, { useState } from 'react';
import Login from './login';
import Signup from './signup';
import { useNavigate } from "react-router-dom";
import '../assets/styles/landing.css';
import logo from '../assets/images/logo.png';
import android from '../assets/images/android.png';
import ios from '../assets/images/apple.png';
import app1 from '../assets/images/app1.png';
import app2 from '../assets/images/app2.png';

const loginSignupContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

function Landing() {
    const [showLogin, setShowLogin] = useState(true);
    const navigate = useNavigate();

    const goToContactUs = () => {
        navigate("/contactUs");
    };

    const goToHome = () => {
        navigate("/user");
    };

    return (
        <div>
            <div id="recaptcha-container"></div>
            <div className="container-fluid tray">
                <div className="row">
                    <div className="col-6 col-md-2">
                        <div className="logo">
                            <img src={logo} alt="Logo" onClick={goToHome} />
                        </div>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="menu">
                            <div className="menu-item" onClick={goToHome}>Home</div>
                            <div className="menu-item" onClick={() => window.location.href = 'https://www.instagram.com/gymmienation/'}>
                                Social Profile
                            </div>
                            <div className="menu-item" onClick={goToContactUs}>Contact Us</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid landingContainer">
                <div className="row justify-content-center bannerRow">
                    <div className="col-md-7">
                        {/* Mobile layout */}
                        <div className="d-md-none" style={loginSignupContainerStyle}>
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-center">
                                        <label className="toggle-button">
                                            <input
                                                type="checkbox"
                                                checked={!showLogin}
                                                onChange={() => setShowLogin(!showLogin)}
                                            />
                                            <span
                                                className={`slider ${showLogin ? '' : 'signup'}`}
                                            />
                                            <span className="toggle-text">SIGNUP</span>
                                            <span className="toggle-login">LOGIN</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {showLogin ? <Signup /> : <Login />}
                                </div>
                            </div>
                        </div>
                        {/* End of mobile layout */}
                        <div className="row appRow">
                            <div className="col-md-4 appColumnFirst">
                                <div className="message">
                                    <h4 className="message-text">Services in your pocket</h4>
                                    <p className="message-p">Review, manage, and take action on the go with the all-new GYMMIE app.</p>
                                    <h4 className="message-text">DOWNLOAD NOW</h4>
                                </div>
                                <div className="storeImages">
                                    <div className="col-6 col-md-6">
                                        <a href="https://play.google.com/store/apps/details?id=com.gymmie" target="_blank" rel="noopener noreferrer">
                                            <img src={android} alt="Android Logo" className="storeImage" />
                                        </a>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <img src={ios} alt="iOS Logo" className="storeImage" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 appColumnSecond">
                                <div className='row appImagesRow'>
                                    <div className='col-md-6 col-6'>
                                        <img src={app1} alt="Android Logo" className="appImage img-fluid" />
                                    </div>
                                    <div className='col-md-6 col-6'>
                                        <img src={app2} alt="Android Logo" className="appImage img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        {/* Desktop layout */}
                        <div className="d-none d-md-block" style={loginSignupContainerStyle}>
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-center">
                                        <label className="toggle-button">
                                            <input
                                                type="checkbox"
                                                checked={!showLogin}
                                                onChange={() => setShowLogin(!showLogin)}
                                            />
                                            <span
                                                className={`slider ${showLogin ? '' : 'signup'}`}
                                            />
                                            <span className="toggle-text">SIGNUP</span>
                                            <span className="toggle-login">LOGIN</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {showLogin ? <Signup /> : <Login />}
                                </div>
                            </div>
                        </div>
                        {/* End of desktop layout */}
                    </div>
                </div>
            </div>
            <div className="disclaimer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="disclaimer-left">
                                <ul className="disclaimer-options">
                                    <li><a href="#">Status</a></li>
                                    <li><a href="privacy.html">Privacy</a></li>
                                    <li><a href="#">Terms and Conditions</a></li>
                                    <li><a href="#">Privacy Settings</a></li>
                                    <li><a href="#">Change Region</a></li>
                                    <li><a href="#">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="disclaimer-right">
                                <ul className="disclaimer-social">
                                    <li><a href="https://www.instagram.com/gymmienation/"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="https://www.youtube.com/@gymmieNation"><i className="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="disclaimer-copyright">
                                <p className="disclaimer-text">&copy; 2023 GYMMIE FITNESS PVT LTD. All rights reserved. Various
                                    trademarks held by their respective owners.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;
