import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { createBusiness } from "../services/api";
import { getToken } from "../services/api";

function Signup() {
  const navigate = useNavigate();
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gymName, setGymName] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const recaptchaVerifierRef = useRef(null); // Use useRef to store recaptchaVerifier

  useEffect(() => {
    // Create the reCAPTCHA verifier when the component mounts
    recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible", // Change this to 'normal' if you want a visible reCAPTCHA
      }
    );

    // Clean up the reCAPTCHA verifier when the component unmounts
    return () => {
      recaptchaVerifierRef.current.clear();
    };
  }, []);

  const handleSignup = async () => {
    if (!/^\d{10}$/.test(phoneNumber) || phoneNumber.startsWith("0")) {
      setPhoneError("Invalid phone number");
      return;
    }
    if(!gymName) {
      alert("Please enter the gym name");
      return;
    }
    try {
      let phone = "+91" + phoneNumber;
      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(phone, recaptchaVerifierRef.current);

      setConfirmationResult(confirmationResult); // Set confirmationResult
      setIsSignedUp(true);
      setPhoneError("");
    } catch (error) {
      console.error("Signup failed:", error);
    }
  };
  const handleVerification = async () => {
    try {
      if (!/^\d{6}$/.test(verificationCode)) { // OTP validation
        setOtpError("Invalid OTP. Please try again.");
        return;
      }
      const userCredential = await confirmationResult.confirm(verificationCode);
      setIsSignedUp(true);
      await createBusiness(gymName, phoneNumber);
      let tokenDetails = await getToken(phoneNumber);
      localStorage.setItem("id", tokenDetails.idToken)

      navigate(`/home`);
    } catch (error) {
      console.error("Verification failed:", error);
    }
  };
  if (isSignedUp) {
    return (
      <div className="login-form">
        <div className="signup-input-container">
          <input
            type="text"
            placeholder="OTP"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </div>
        {otpError && <p style={{ color: "red" }}>{otpError}</p>} {/* Display OTP error message */}
        <button className="btn btn-warning btn-lg" onClick={handleVerification}>
          Verify OTP
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <div className="signup-input-container">
          <input
            type="text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="signup-input-container">
          <input
            type="text"
            placeholder="Gym Name"
            value={gymName}
            onChange={(e) => setGymName(e.target.value)}
          />
        </div>
        <button className="btn btn-warning btn-lg" onClick={handleSignup}>
          Signup
        </button>
      </div>
    );
  }
}

export default Signup;
