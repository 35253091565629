import React, { useEffect, useState } from "react";
import { useDataContext } from "../../DataContext";
import { getBusinessDetails, getAttendance, markAttendance } from "../../services/api"; // Update with your API functions
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEdit, faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import membersStyle from "./../../assets/styles/members.module.css"; // Import the CSS module
import loading from "./../../assets/images/loading.gif";
import { useNavigate } from "react-router-dom";


const appendKey = (val) => {
    let arr = [];
    for(let k in val) {
        let obj = {
            "key" : k
        }
        arr.push({ ...val[k], ...obj });
    }
    return arr;
}

function Staff() {
    const { apiData, setApiData } = useDataContext();
    const navigate = useNavigate(); // Use useNavigate for navigation
    let id = localStorage.getItem('id')
    let userId = ""
    const tokenParts = id.split('.');
    if (tokenParts.length !== 3) {
        console.error("Invalid JWT token format");
    } else {
        // Decode the payload (which is in the second part)
        const base64Payload = tokenParts[1];
        const payload = JSON.parse(atob(base64Payload)); // Decode base64 and parse JSON

        // Access the user_id from the payload
        userId = payload.user_id;
    }
    const phoneNumber = userId
    const [isLoading, setIsLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false); // State for expansion
    useEffect(() => {
        if (!localStorage.getItem('id')) {
            navigate('/')
        }
        const fetchData = async () => {
            try {
                let businessDetails = await getBusinessDetails(phoneNumber);
                const staff = businessDetails[0].staff;
                console.log("members data :", staff);
                setApiData({ ...businessDetails[0], staff });
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [navigate, phoneNumber, setApiData]);

    const membersData = apiData?.staff || [];
    const members = appendKey(membersData || {});

    const [selectedDate, setSelectedDate] = useState("");

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0];
        setSelectedDate(currentDate);
    }, []);


    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };


    const [searchQuery, setSearchQuery] = useState("");
    const [searchManualQuery, setSearchManualQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleManualSearch = (event) => {
        setSearchManualQuery(event.target.value);
    };

    const filteredMembers = searchQuery
        ? members.filter(member =>
            member.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : members;


    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [markResponseError, setMarkResponseError] = useState({});


    const addNewMemberRoute = () => {
        navigate("/staff/addStaff/");
    }

    const editMemberRoute = (key) => {
        navigate("/staff/editStaff/" + key);
    }



    return (
        <div>
            {isLoading ? (
                <div className="loading-container">
                    <div className="loading-message">
                        <img src={loading} alt="Loading..." />
                    </div>
                </div>
            ) : (
                <div className={`${membersStyle.membersBody}`}>
                    <div className={`row ${membersStyle.manualAttendance}`}>
                        <div className="text-end">
                            <button type="button" className="btn btn-primary" onClick={() => {
                                addNewMemberRoute();
                            }}>Add New Staff</button>
                        </div>
                    </div>


                    <div className={`row ${membersStyle.memberRow}`}>
                        <div className="row" style={{marginBottom : 10}}>
                            <h4 className="col-md-10">New Staffs ( {members.length} )</h4>
                        </div>
                        <div className="row">
                            {members.slice(0, isExpanded ? members.length : 11).map((member, index) => (
                                <div key={index} className={`${membersStyle.memberColumn}`} onClick={() => editMemberRoute(member?.key)}>
                                    <div className={`${membersStyle.memberCard}`}>
                                        <img src={member.photo} alt={member.name} className={`${membersStyle.memberPhoto}`} />
                                        <div className={`${membersStyle.cardBody}`}>
                                            <h5 className={`${membersStyle.cardTitle}`}>{member.name}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div
                                className={`col-md-1 ${membersStyle.memberColumn} ${membersStyle.expandIcon}`}
                                onClick={toggleExpansion} style={{width: 20}}
                            >
                                <i className={`fas ${isExpanded ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                            </div>
                        </div>
                    </div>
                    <div className={`row ${membersStyle.memberRow}`}>
                        <div className="row" style={{marginBottom: 20}}>
                            <h4 className="col-sm-10">Total Staffs ( {members.length} )</h4>
                            <div className={`col-sm-2 ${membersStyle.searchBox}`}>
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    className={`col-md-12 ${membersStyle.searchInput}`}
                                />
                            </div>
                        </div>
                        <div className="row">
                            {filteredMembers.slice(0, isExpanded ? members.length : 11).map((member, index) => (
                                <div key={index} className={`${membersStyle.memberColumn}`} onClick={() => editMemberRoute(member?.key)}>
                                    <div className={`${membersStyle.memberCard}`}>
                                        <img src={member.photo} alt={member.name} className={`${membersStyle.memberPhoto}`} />
                                        <div className={`${membersStyle.cardBody}`}>
                                            <h5 className={`${membersStyle.cardTitle}`}>{member.name}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div
                                className={`col-md-1 ${membersStyle.memberColumn} ${membersStyle.expandIcon}`}
                                onClick={toggleExpansion} style={{width: 20}}
                            >
                                <i className={`fas ${isExpanded ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Staff;
