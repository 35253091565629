import React, { useState } from 'react';
import sidebarStyle from "../../assets/styles/sidebar.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowLeft, faHome, faUsers, faCalendar, faDumbbell, faBell, faSignOutAlt, faMoneyBillAlt, faToolbox, faBuilding, faChartBar, faCamera } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useHistory
import { useDataContext } from '../../DataContext';
import { getPermissions } from '../../global/util';
import icon from "./../../assets/images/icon.png"

const menuItems = [
    { text: 'Home', icon: faHome, link: '/home', show: true },
    { text: 'Members', icon: faUsers, link: '/members', show: getPermissions("members")?.list },
    { text: 'Events', icon: faCalendar, link: '/events', show: getPermissions("events")?.list },
    { text: 'Revenue', icon: faMoneyBillAlt, link: '/home', show: getPermissions("revenue")?.list },
    { text: 'Workouts', icon: faDumbbell, link: '/home', show: getPermissions("workouts")?.list },
    { text: 'Equipments', icon: faToolbox, link: '/equipments', show: getPermissions("equipments")?.list },
    { text: 'My Gym', icon: faBuilding, link: '/profile', show: true },
    { text: 'Staff', icon: faUsers, link: '/staff', show: getPermissions("staffs")?.list },
    { text: 'Attendance', icon: faCamera, link: '/attendance', show: getPermissions("attendance")?.list  },
    { text: 'Analytics', icon: faChartBar, link: '', show: getPermissions("analytics")?.list },
    { text: 'Logout', icon: faSignOutAlt, link: '/', show: true },
];


let obj1 = { padding: "16px", color: "white" }
let textObj = { marginLeft: 8, fontSize: 14, color: "gray" }
let divObj = { padding: "8px 12px", color: "gray" }

function Sidebar() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const location = useLocation();
    const { apiData } = useDataContext();

    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLogout = () => {
        // Remove the session flag from sessionStorage when logged out
        localStorage.removeItem("id");
        navigate("/");
    };


    return (
        <div>
            <div className={`${sidebarStyle.sidebar} ${isSidebarOpen ? sidebarStyle.open : ''}`} style={{ backgroundColor: "rgba(0,0,0,1)"}}>
                <div style={{ marginTop: 10 }}>
                    <img src={icon} />
                </div>
                <div className={`${sidebarStyle.toggleIcon}`} onClick={toggleSidebar}>
                    {/* <FontAwesomeIcon icon={isSidebarOpen ? faArrowLeft : faBars} /> */}
                </div>
                <div style={obj1}>
                    <div style={{ ...divObj, color: "white"}}>
                         <FontAwesomeIcon
                            icon={faHome}
                            className={`${sidebarStyle.menuIcon}`}
                            style={{
                                transition: 'none', // Remove hover animation
                            }}
                        />
                        <span style={{ ...textObj, color: "white"}}> Overview </span>
                    </div>
                </div>

                <p style={{ border: "1px solid #727272", marginTop: "12px", marginBottom: "12px", marginLeft: "10%", width: "80%"}} />
                
                <div style={obj1}>
                    <div style={divObj}>
                            <FontAwesomeIcon
                                icon={faUsers}
                                className={`${sidebarStyle.menuIcon}`}
                                style={{
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                        <span style={textObj}> Members </span>
                    </div>
                    <div style={divObj}>
                            <FontAwesomeIcon
                                icon={faUsers}
                                className={`${sidebarStyle.menuIcon}`}
                                style={{
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                        <span style={textObj}> Staff </span>
                    </div>
                </div>


                <p style={{ border: "1px solid #727272", marginTop: "12px", marginBottom: "12px", marginLeft: "10%", width: "80%"}} />

                <div style={obj1}>
                    <div style={divObj}>
                            <FontAwesomeIcon
                                icon={faCalendar}
                                className={`${sidebarStyle.menuIcon}`}
                                style={{
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                        <span style={textObj}> Events </span>
                    </div>
                    <div style={divObj}>
                            <FontAwesomeIcon
                                icon={faToolbox}
                                className={`${sidebarStyle.menuIcon}`}
                                style={{
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                        <span style={textObj}> Equipments </span>
                    </div>
                </div>

                <p style={{ border: "1px solid #727272", marginTop: "12px", marginBottom: "12px", marginLeft: "10%", width: "80%"}} />

                <div style={obj1}>
                    <div style={divObj}>
                            <FontAwesomeIcon
                                icon={faCalendar}
                                className={`${sidebarStyle.menuIcon}`}
                                style={{
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                        <span style={textObj}> My Gym </span>
                    </div>
                    <div style={divObj}>
                            <FontAwesomeIcon
                                icon={faToolbox}
                                className={`${sidebarStyle.menuIcon}`}
                                style={{
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                        <span style={textObj}> Revenue </span>
                    </div>
                </div>

                <p style={{ border: "1px solid #727272", marginTop: "12px", marginBottom: "12px", marginLeft: "10%", width: "80%"}} />

                <div style={obj1}>
                    <div style={divObj}>
                            <FontAwesomeIcon
                                icon={faCalendar}
                                className={`${sidebarStyle.menuIcon}`}
                                style={{
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                        <span style={textObj}> Attendance </span>
                    </div>
                </div>

                <p style={{ border: "1px solid #727272", marginTop: "12px", marginBottom: "12px", marginLeft: "10%", width: "80%"}} />


                



                {/* <div className={`${sidebarStyle.menuItems}`}>
                    {menuItems.filter(item => item.show ).map((item, index) => (
                        <div
                            key={index}
                            to={item.link}
                            target={item.text === 'Attendance' ? '_blank' : ''}
                            className={`${sidebarStyle.menuItem} ${item.text === 'Logout' ? sidebarStyle.logoutItem : ''} ${location.pathname === `${item.link}` ? 'active' : ''}`}
                            aria-label={item.text}
                            onClick={() => {
                                if (item.text === 'Logout') {
                                    handleLogout(); // Call your logout function here
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={item.icon}
                                className={`${sidebarStyle.menuIcon} ${location.pathname === `${item.link}` ? 'active' : ''
                                    } ${['Revenue', 'Workouts', 'Analytics'].includes(item.text) ? sidebarStyle.grayIcon : ''
                                    }`}
                                style={{
                                    color: ['Revenue', 'Workouts', 'Analytics'].includes(item.text) ? 'gray' : '',
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                            {isSidebarOpen && (
                                <span
                                    className={`${sidebarStyle.menuText} ${location.pathname === `${item.link}` ? 'active' : ''
                                        } ${['Revenue', 'Workouts', 'Analytics'].includes(item.text) ? sidebarStyle.grayIcon : ''
                                        }`}
                                    style={{
                                        color: ['Revenue', 'Workouts', 'Analytics'].includes(item.text) ? 'gray' : '',
                                        transition: 'none', // Remove hover animation
                                    }}
                                >
                                    {item.text}
                                </span>
                            )}
                        </div>
                    ))}
                </div> */}
            </div>
        </div>
    );
}

export default Sidebar;
