// src/components/Footer.js

import React from 'react';
import '../../assets/styles/footer.css';

function Footer() {
    return (
        <footer className="footer">
            {/* Your footer content */}
        </footer>
    );
}

export default Footer;
