import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export function useDataContext() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [apiData, setApiData] = useState(null);

    return (
        <DataContext.Provider value={{ apiData, setApiData }}>
            {children}
        </DataContext.Provider>
    );
}
