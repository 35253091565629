import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { useDataContext } from './../../DataContext'
import { getBusinessDetails, getSchedule, editEnquiry, addEnquiry } from "./../../services/api";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

let obj = {
    height: "100vh",  
    width: "100vw", 
    position: "fixed",
    top: "0px",
    left: "0px",
    backgroundColor: "rgb(0,0,0,0.8)",
    zIndex: 9
}

let textObj = {
    fontSize: 16, paddingLeft: 0, marginBottom: 0, marginTop: 8
}

let textInputObj = {
    height: "60px",
    backgroundColor: "black",
    borderRadius: "16px",
    color: "white"
}

export default (props) => { 
    
  const { apiData, setApiData } = useDataContext();
  const phoneNumber = window.localStorage.phoneNumber;

  if(!props.openEnquiry) return null;

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [source, setSource] = useState("");
  const [reference, setReference] = useState("");

  const [poc, setPoc] = useState("");
  const [interestType, setInterestType] = useState("");
  const [remarks, setRemarks] = useState("");

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {

    if(props.selectedEnquiry && props.edit) {
        let data = props.selectedEnquiry;
        setName(data.name);
        setPhone(data.phone);
        setSource(data.source);
        setReference(data.reference);
        setPoc(data.poc);
        setInterestType(data.interestType);
        setRemarks(data.remarks);
    } 
  }, [props.selectedEnquiry]);

  const validateEnquiry = () => {
    if(!name) {
        setErrMsg("Please enter name");
        return false;
    }
    if(!phone) {
        setErrMsg("Please enter phone");
        return false;
    }
    if(phone && !phone.match('[0-9]{10}')) {
        setErrMsg("Invalid Phone number");
        return false;
    }
    if(!source) {
        setErrMsg("Please enter source");
        return false;
    }
    if(!reference) {
        setErrMsg("Please enter reference");
        return false;
    }
    return true;
  }

  const _editEnquiry = async () => {
    let userDetail = apiData?.name;
    let obj = {
        "poc": userDetail?.name,
        "metadata": {
            "utmSource": reference,
            "referralSource": reference,
        },
        reference, phone,
        name, source,
        poc,remarks, interestType
    };
    try {
       let res = await editEnquiry(props?.selectedEnquiry?.phone, obj);
       if(res?.error) {
        props.toggleEnquiry(false);
        return props.toggleErrorMessage(res?.error || res?.message)
       }
       props.toggleEnquiry(false);
       props.toggleErrorMessage("Enquiry updated successfully");
       props.setRefresh();
    } catch(err) {
        props.toggleEnquiry(false);
        props.toggleErrorMessage(err?.error || err?.message);
    }
  }

  const _addEnquiry = async () => {
    let userDetail = apiData?.name;
    let obj = {
        "reference": reference,
        "metadata": {
            "utmSource": reference,
            "referralSource": reference,
        },
        "phone": phone,
        "poc": userDetail?.name,
        "name": name,
        "source": source,
    };
    try {
        let res = await addEnquiry(obj);
        if(res?.error) {
            props.toggleEnquiry(false);
            return props.toggleErrorMessage(res?.error || res?.message)
        }
        props.toggleEnquiry(false);
        props.toggleErrorMessage("Enquiry created successfully");
        props.setRefresh();
    } catch(err) {
        props.toggleErrorMessage(err?.error || err?.message);
    }
  }

  return (
    <div style={obj}>
        <div className="modal" style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.1)" }}>
        <Modal.Dialog onHide={()=> props.toggleEnquiry(false)} centered>
            <Modal.Header>
                <Modal.Title style={{ fontSize: "22px", color: "#FFBB0F", fontWeight: "bold" }}>{"Enquiry".toUpperCase()}</Modal.Title>
            </Modal.Header>

            <Modal.Body >
                <div className="container-fluid">
                    {
                        errMsg ? <div class="row">
                            <span style={{ color: "red", marginLeft: "-15px" }}> { errMsg } </span>
                        </div> : null
                    }
                    <div class="row">
                        {/* <Form.Label htmlFor="name" style={textObj}>Name</Form.Label> */}
                        <Form.Control onChange={(e) => {
                            setName(e.target.value);
                            setErrMsg("");
                        }}
                            placeholder="Name"
                            type="text"
                            id="name"
                            value={name}
                            aria-describedby="passwordHelpBlock"
                            style={textInputObj}
                        />
                    </div>
                    <div class="row mt-2">
                        {/* <Form.Label htmlFor="phone" style={textObj}>Phone</Form.Label> */}
                        <Form.Control onChange={(e) => {
                            setPhone(e.target.value);
                            setErrMsg("");
                        }}  value={phone}
                            placeholder="Phone"
                            type="tel"
                            id="phone"
                            aria-describedby="passwordHelpBlock"
                            style={textInputObj}
                        />
                    </div>
                    <div class="row mt-2">
                        {/* <Form.Label htmlFor="source" style={textObj}>Source</Form.Label> */}
                        <Form.Control onChange={(e) => {
                            setSource(e.target.value);
                            setErrMsg("");
                        }}  value={source}
                            placeholder="Source"
                            type="text"
                            id="source"
                            aria-describedby="passwordHelpBlock"
                            style={textInputObj}
                        />
                    </div>
                    <div class="row mt-2">
                        {/* <Form.Label htmlFor="reference" style={textObj}>Reference</Form.Label> */}
                        <Form.Control onChange={(e) => {
                            setReference(e.target.value);
                            setErrMsg("");
                        }}  value={reference}
                            placeholder="Reference"
                            type="text"
                            id="reference"
                            style={textInputObj}
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    {
                        props.edit ? <>
                            <div class="row mt-2">
                                {/* <Form.Label htmlFor="poc" style={textObj}>POC</Form.Label> */}
                                <Form.Control onChange={(e) => {
                                    setPoc(e.target.value);
                                    setErrMsg("");
                                }}  value={poc}
                                    type="text"
                                    id="poc"
                                    placeholder="POC"
                                    aria-describedby="passwordHelpBlock"
                                    style={textInputObj}
                                />
                            </div>
                            <div class="row mt-2">
                                {/* <Form.Label htmlFor="interestType" style={textObj}>Interest Type</Form.Label> */}
                                <Form.Control onChange={(e) => {
                                    setInterestType(e.target.value);
                                    setErrMsg("");
                                }}  value={interestType}
                                    placeholder="Interest Type"
                                    type="text"
                                    id="interestType"
                                    aria-describedby="passwordHelpBlock"
                                    style={textInputObj}
                                />
                            </div>
                            <div class="row mt-2">
                                {/* <Form.Label htmlFor="remarks" style={textObj}>Remarks</Form.Label> */}
                                <Form.Control onChange={(e) => {
                                    setRemarks(e.target.value);
                                    setErrMsg("");
                                }}  value={remarks}
                                    placeholder="Remarks"
                                    type="text"
                                    id="remarks"
                                    aria-describedby="passwordHelpBlock"
                                    style={textInputObj}
                                />
                            </div>

                        </> : null
                    }
                </div>
            </Modal.Body>

            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="danger" style={{ flex: 1, height: 48 }} onClick={() => props.toggleEnquiry(false)}>Cancel</Button>
            <Button variant="success" style={{ flex: 1, height: 48  }} onClick={() => {
                if(!validateEnquiry())return;
                if(props.edit) _editEnquiry();
                else _addEnquiry();
            }}>
                {props.edit ? "Update" : "Create" }</Button>
            </Modal.Footer>
        </Modal.Dialog>
        </div>
    </div>
  )

}