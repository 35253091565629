import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/common/header";
import Footer from "./components/common/footer";
import Sidebar from "./components/common/sidebar";
import { useDataContext } from "./../src/DataContext";
import Home from "./pages/Home";
import Enquiry from "./pages/enquiry/Enquiry";
import Landing from "./components/landing";
import firebase from "firebase/compat/app"; // Note the use of 'compat/app'
import "firebase/compat/auth";
import Profile from "./pages/profile";
import Attendance from "./pages/attendance"
import User from "./pages/user/userDashboard"
import UserProfile from "./pages/user/userProfile"
import { DataProvider } from './DataContext';
import Equipment from "./pages/equipment/equipments";
import Event from "./pages/event/events";
import AddEvent from "./pages/event/addEvent"
import AddMember from "./pages/member/addMember";
import AddStaff from "./pages/staff/addStaff";
import EditMember from "./pages/member/editMember";
import EditStaff from "./pages/staff/editStaff";
import AddEquipment from "./pages/equipment/addEquipment";
import EditEquipment from "./pages/equipment/editEquipment";
import EditEvent from "./pages/event/editEvent";
import ContactUs from "./pages/contactUs";
import Analytics from "./pages/analytics";
import Members from "./pages/member/members";
import Staff from "./pages/staff/staff";
import { useLocation, useNavigate } from 'react-router-dom';
import { getPermissions } from "./global/util";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);



function clearLocalStorageAndSessionStorage() {
  // Clear Local Storage
  localStorage.clear();

  // Clear Session Storage
  sessionStorage.clear();
}

function App() {
  return (
    <DataProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Landing />} />
            {/* Home page with Header and Sidebar */}
            <Route path="enquiry/*" element={<EnquiryWithLayout />} />
            {/* <Route path="home/*" element={<HomeWithLayout />} />
            <Route path="profile/*" element={<ProfileWithLayout />} />
            <Route path="attendance" element={<AttendanceWithLayout />} />
            {
              getPermissions("equipments")?.list ? <Route path="equipments/*" element={<EquipmentWithLayout />} /> : null
            }
            {
              getPermissions("events")?.list ? <Route path="events/*" element={<EventWithLayout />} /> : null
            }
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/analytics" element={<AnalyticsWithLayout />} />
            {
              getPermissions("members")?.list ? <Route path="/members/*" element={<MembersWithLayout />} /> : null
            }
            <Route path="user/*" element={<UserWithLayout />} />
            {
              getPermissions("staffs")?.list ?  <Route path="/staff/*" element={<StaffWithLayout />} /> : null 
            } */}
          </Routes>
          <Footer />
        </div>
      </Router>
    </DataProvider>
  );
}

function HomeWithLayout() {
  return (
    <>
      <Header />
      <div className="content">
        <Sidebar />
        <main className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            {/* Add more Route components for other pages */}
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

function EnquiryWithLayout() {
  return (
    <>
      <div className="content">
        <Sidebar />
        <main className="main">
          <Routes>
            <Route path="/" element={<Enquiry />} />
            {/* Add more Route components for other pages */}
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

function ProfileWithLayout() {
  return (
    <>
      <div className="content">
        <Sidebar />
        <main className="main">
          <Routes>
            <Route path="/" element={<Profile />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

function AttendanceWithLayout() {
  let permissions = getPermissions();
  // if(!permissions["attendance"]) {
  //   return <div></div>
  // }
  return (
    <>
      <div className="content">
        <main className="main">
          <Routes>
            <Route path="/" element={<Attendance />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

function UserWithLayout() {
  // let permissions = getPermissions();
  // if(!permissions["attendance"]) {
  //   return <div></div>
  // }
  return (
    <>
      <div className="content">
        <main className="main">
          <Routes>
            <Route path="/" element={<User />} />
            <Route path="/profile" element={<UserProfile />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

function EquipmentWithLayout() {
  let permissions = getPermissions("equipments");
  if(!permissions) {
    return <div></div>
  }
  return (
    <>
      <Header />
      <div className="content">
        <Sidebar />
        <main className="main">
          <Routes>
            {
              permissions?.list ? <Route path="/" element={<Equipment />} /> : null
            }
            {
              permissions?.create ? <Route path="/addEquipment" element={<AddEquipment />} /> : null
            }
            {
              permissions?.update ? <Route path="/editEquipment/*" element={<EditEquipment />} /> : null 
            }
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

function EventWithLayout() {
  let permissions = getPermissions("events");
  if(!permissions) {
    return <div></div>
  }
  return (
    <>
      <Header />
      <div className="content">
        <Sidebar />
        <main className="main">
          <Routes>
            {
              permissions?.list ?  <Route path="/" element={<Event />} /> : null 
            }
            {
              permissions?.create ?  <Route path="/addEvent" element={<AddEvent />} /> : null 
            }
            {
              permissions?.update ?  <Route path="/editEvent/*" element={<EditEvent />} /> : null
            }
            
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

function AnalyticsWithLayout() {
  let permissions = getPermissions("analytics");
  if(!permissions) {
    return <div></div>
  }
  return (
    <>
      <Header />
      <div className="content">
        <Sidebar />
        <main className="main">
          <Routes>
            <Route path="/" element={<Analytics />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}
function MembersWithLayout() {
  const { apiData, setApiData } = useDataContext();
  let permissions = getPermissions("members");
  if(!permissions) {
    return <div></div>
  }
  return (
    <>
      <Header />
      <div className="content">
        <Sidebar />
        <main className="main">
          <Routes>
          {
              permissions?.list ?  <Route path="/" element={<Members />} /> : null 
          }
          {
              permissions?.create ?  <Route path="/addMember" element={<AddMember />} /> : null 
          }
          {
              permissions?.update ?  <Route path="/editMember/*" element={<EditMember />} /> : null 
          }
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

function StaffWithLayout() {
  let permissions = getPermissions("staffs");
  if(!permissions) {
    return <div></div>
  }
  return (
    <>
      <Header />
      <div className="content">
        <Sidebar />
        <main className="main">
          <Routes>
          {
              permissions?.list ?  <Route path="/" element={<Staff />} /> : null 
          }
          {
              permissions?.create ?  <Route path="/addStaff" element={<AddStaff />} /> : null 
          }
          {
              permissions?.update ?  <Route path="/editStaff/*" element={<EditStaff />} /> : null 
          }
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default App;
