import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import { faUsers, faUserPlus, faTicketAlt, faEdit, faUser, faCheck, faIdCard, faL } from "@fortawesome/free-solid-svg-icons";

const nameVsIcon = {
  name: faUser,
  email: "envelope-o",
  phoneNumber: "phone",
  phone: "phone",
  gender: "venus-mars",
  duration: "clock-o",
  bmi: "bar-chart",
  dob: "calendar-plus-o",
  gender: 'venus-mars',
  plan: 'clock-o',
  startDate: "calendar-plus-o",
  date: "calendar-plus-o",
  maintenanceCycle: "calendar-plus-o",
  salaryPerMonth : "rupee",
  role: "user-o",
  description: "tasks",
  prize: "gift",
  monthly: "rupee",
  quarterly: "rupee",
  halfYearly: "rupee",
  yearly: "rupee"
}

export default ({ text, value, name, ph, onChangeText, type, checkRequired, dropDownText, dropDownOptions, onChangeFile }) => {

    const [isEmpty, setIsEmpty] = useState(false);

    const onChangeFunc = (val) => {
      let isEmpty = false;
      if(checkRequired && !val.target.value) {
        isEmpty = true;
      }
      setIsEmpty(isEmpty);
      onChangeText(name, val.target.value);
    }

    useEffect(() => {
      if(checkRequired && !value) {
        setIsEmpty(true)
      }
    }, [checkRequired])

    const getFormControl = () => {
      if(type == "textarea") {
        return <Form.Control
          onChange={onChangeFunc}
          id={name}
          type={"text"}
          as={"textarea"} rows={4}
          value={value}
          placeholder={ph}
          style={{ paddingLeft: 10, height: 120 }}
        />
      }
      return <Form.Control
          onChange={onChangeFunc}
          id={name}
          type={ type ? type : "text"}
          value={value}
          placeholder={ph}
          style={{ paddingLeft: 10 }}
        />
    }

    const handleFileChange = (e) => {
      const file = e.target.files[0]; // Get the first selected file
      if (file) {
        const reader = new FileReader();
        
        reader.onload = (e) => {
          const base64String = e.target.result;
          console.log(base64String); // Log the base64 string to the console
          // You can use the base64String for further processing here
          onChangeFile(name, base64String);
        };
        
        reader.readAsDataURL(file); // Start reading the file as a data URL (base64)
      }
    };

    if(type == "file") {
      return <div>
        <Form.Group controlId="formFile" className="mb-3">
        <Form.Label style={{ color: "white"}}>Choose Profile Photo</Form.Label>
        <Form.Control type="file" accept="image/png, image/gif, image/jpeg" onChange={handleFileChange} />
      </Form.Group>
      </div>
    }

    // if(type == "dropdown") {
    //   return <div style={{ marginTop: 8, zIndex: 99,position: "relative", marginBottom: 100 }}>
    //             <DropdownButton title="Dropdown button" style={{ width: "100%", height: 60}}>
    //                 {
    //                   dropDownOptions.map((item, index) => (
    //                     <Dropdown.Item href="#/action-1">{item.name}</Dropdown.Item>
    //                   ))
    //                 }
    //               {/* </Dropdown.Menu> */}
    //             </DropdownButton>
    //         </div>
    // }

    return (
      <>
      <Form.Floating className="mb-3" style={{ position: "relative" }}>
        {/* <div style={{ position: "absolute", left: 15, top: 22 }}>
          <FontAwesomeIcon icon={nameVsIcon[name]} size={"1x"} />
        </div> */}
        {
          getFormControl()
        }
        <label htmlFor={name}>{text}</label>
        {
          isEmpty ? <Form.Control.Feedback type="invalid" style={{display: "block"}}>{text} is a required field</Form.Control.Feedback> : null
        }
      </Form.Floating>
    </>
    )
}