export const getPermissions = (serviceType) => {
    let obj = {
        "read": true,
        "create": true,
        "update": true,
        "list": true,
        "delete": true
    }
    let userType = window.localStorage.userType;
    if(userType == "owner") {
        return obj;
    }
    if(userType == "manager" && serviceType == "revenue") {
        return {};
    }
    if(userType == "manager") {
        return obj;
    }
    let permissions = JSON.parse(window.localStorage.permissions || "{}");
    if(!serviceType) {
        return permissions;
    }
    return permissions[serviceType] || {};
}