import React, { useEffect, useState, useReducer } from "react";
import { faUser, faEdit, faCheck, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import loading from "../assets/images/loading.gif";
export default props => {
    return <div  style={{ paddingTop: 20, position: "relative"}}>
        {props.children}
        {
            props.showDelete ? <div style={{
                position: "absolute",
                top: 20,
                right: 0,
                width: 40,
                height: 40
            }}> 
                <FontAwesomeIcon icon={faTrash} style={{
                    color: "rgb(255, 187, 15)",
                    fontSize: 24,
                    cursor: "pointer"
                }} onClick={() => props.onDelete()}/>   
            </div> : null
        }
        {
            props.isLoading ? (
                <div style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", zindex: 999, 
                            display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(0, 0,0, 0.8)" }}>
                        <img src={loading} />
                </div>) : null
        }
    </div>
}