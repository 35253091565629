import React, { useState } from 'react';
import './../../assets/styles/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faAdd, faPowerOff, faSearch } from '@fortawesome/free-solid-svg-icons';
import dpImage from '../../assets/images/dp.png';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useDataContext } from '../../DataContext'

let inputElement = document.getElementById("exampleForm.ControlInput1");
if(inputElement) {
    inputElement.addEventListener("click", function(event) {
        event.preventDefault(); // Prevent default action
    });
}

function EnquiryHeader(props) {
    const { apiData } = useDataContext();

    const [searchText, setSearchText] = useState("");

    const searchEnquiry = (val) => {
        // if(!val) {
        //     // return props.toggleErrorMessage("Please enter phone number");
        //     return;
        // } 
        if(val && !val.match('[0-9]{10}')) {
            // return props.toggleErrorMessage("Please enter valid phone number");
            return;
        }
        props.searchNumber(val);
    }

    return (
        <header className="header">
            <div className="profile">
            
            </div>
            <div style={{ width: "100%", paddingLeft: 150, display: "flex" }}>
                <div style={{ width: "60%", display: "flex", alignItems: "center" }}>
                <Form>
                    <Form.Group controlId="exampleForm.ControlInput1" style={{ position: "relative" }}>
                        <span style={{ position: "absolute", top: 10, left: 10 }}>
                            <FontAwesomeIcon
                                icon={faSearch}
                                style={{
                                    fontSize: 14,
                                    transition: 'none', // Remove hover animation
                                }}
                            />
                        </span>
                        <Form.Control type="text" onChange={(e) => {
                            // if(e.target.charCode != 13) return;
                            searchEnquiry(e.target.value);
                            // e.preventDefault();
                        }} placeholder="Search" style={{ marginLeft: 10 }} />
                    </Form.Group>
                </Form>
                </div>
                <div style={{ width: "40%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Button variant="warning" onClick={() => {
                        props.toggleEdit(false);
                        props.toggleEnquiry(true);
                    }}>
                        <FontAwesomeIcon
                            icon={faAdd}
                            style={{
                                fontSize: 14,
                                transition: 'none', // Remove hover animation
                            }}
                        />
                        <span style={{ fontSize: 14, paddingLeft: 4 }}> Create Enquiry </span>
                    </Button>{' '}
                    {/* <Button style={{
                        color: "#FF0F0F",
                        border: "1px solid #FF0F0F",
                        backgroundColor: "black",
                        marginLeft: 16
                    }}>
                        <FontAwesomeIcon
                            icon={faAdd}
                            style={{
                                fontSize: 14,
                                transition: 'none', // Remove hover animation
                            }}
                        />
                        <span style={{ fontSize: 14, paddingLeft: 4 }}> Create Enquiry </span>
                    </Button>{' '} */}
                    <div style={{ marginLeft: 8 }}>
                        <img src={dpImage} style={{ width: 50, height: 50 }} />
                    </div>
                    <div style={{ marginLeft: 8, cursor: "pointer" }} onClick={()=> {
                        props.showLogoutModal(true);
                    }}>
                        <FontAwesomeIcon
                            icon={faPowerOff}
                            style={{
                                fontSize: 14,
                                transition: 'none', // Remove hover animation
                            }}
                        />
                        {/* <p style={{ marginBottom: 0, fontSize: 14 }}>Raghib</p>
                        <p style={{ marginBottom: 0, fontSize: 12, color: "rgba(255, 255, 255, 0.57)" }}>Gym Manager</p> */}
                    </div>
                </div>
            </div>
            <div className="notification">
                
                {/* <FontAwesomeIcon icon={faBell} /> */}
            </div>
        </header>
    );
}

export default EnquiryHeader;