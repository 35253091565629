import React, { useEffect, useState } from "react";
import { useDataContext } from "../../DataContext";
import { getBusinessDetails } from "../../services/api"; // Update with your API functions
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEdit, faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import eventStyle from "./../../assets/styles/event.module.css"; // Import the CSS module
import Layout from "./../../container/layout";
import { getPermissions } from "./../../global/util";
import loading from "./../../assets/images/loading.gif";
import { useNavigate } from "react-router-dom";

const appendKey = (val) => {
    let arr = [];
    for(let k in val) {
        let obj = {
            "key" : k
        }
        arr.push({ ...val[k], ...obj });
    }
    return arr;
}

function Event() {
    const { apiData, setApiData } = useDataContext();
    const navigate = useNavigate(); // Use useNavigate for navigation
    let id = localStorage.getItem('id')
    let userId = ""
    const tokenParts = id?.split('.');
    if (tokenParts.length !== 3) {
        console.error("Invalid JWT token format");
    } else {
        // Decode the payload (which is in the second part)
        const base64Payload = tokenParts[1];
        const payload = JSON.parse(atob(base64Payload)); // Decode base64 and parse JSON

        // Access the user_id from the payload
        userId = payload.user_id;
    }
    const phoneNumber = userId
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!localStorage.getItem('id')) {
            navigate('/')
        }
        const fetchData = async () => {
            try {
                let businessDetails = await getBusinessDetails(phoneNumber);
                const events = businessDetails[0].events;
                console.log("events data :", events);
                setApiData({ ...businessDetails[0], events });
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [navigate, phoneNumber, setApiData]);

    const _editEvent = (event) => {
        navigate("/events/editEvent/"+ event.key,{ state:{eventId:event.key}});
    }

    const totalEvents = appendKey(apiData?.events || {});
    console.log("total events :", totalEvents);

    return (
        <Layout isLoading={isLoading} >
                <div className={`col-md-12 ${eventStyle.eventBody}`}>
                    <div className="row">
                        {totalEvents.map((event, index) => (
                            <div key={index} className={`col-md-4 ${eventStyle.sideRow}`}>
                                <div className={`${eventStyle.eventCard}`}>
                                    <div className={`d-flex justify-content-between ${eventStyle.actionOverlay}`}>
                                        <div className="d-flex align-items-center">
                                            {
                                                getPermissions("events")?.update ? <FontAwesomeIcon icon={faEdit} className={`${eventStyle.cardIcon}`} onClick={() => _editEvent(event)} /> : null
                                            }
                                            <FontAwesomeIcon icon={faCheck} className={`${eventStyle.cardIcon}`} />
                                        </div>
                                    </div>
                                    <img src={event.photo} alt="Event" className={`${eventStyle.eventImage}`} />
                                    <div className={`${eventStyle.cardOverlay}`}>
                                        <div className={`${eventStyle.eventRow}`}>
                                            <div className={`${eventStyle.eventName}`}>{event.name}</div>
                                            <div className={`${eventStyle.userCount}`}>
                                                <FontAwesomeIcon icon={faUser} className={`${eventStyle.cardIcon}`} />
                                                <span>150</span>
                                            </div>
                                        </div>
                                        <div className={`${eventStyle.eventData}`}>
                                            {(() => {
                                                const dateUTC = new Date(event.date);
                                                const ISTOffset = 330; // IST offset in minutes
                                                const ISTTime = new Date(dateUTC.getTime() + ISTOffset * 60 * 1000);
                                                return ISTTime.toLocaleString("en-IN", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    second: "numeric",
                                                    timeZoneName: "short",
                                                });
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <div className={`${eventStyle.addEventButton}`} onClick={() => {
                            navigate("/events/addEvent");
                        }}>
                            <FontAwesomeIcon icon={faPlus} className={`${eventStyle.addEventIcon}`} />
                        </div>
                    </div>
                </div>
        </Layout>
    );
}

export default Event;
